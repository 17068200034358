/**
 * @NOTE: See variables.scss for accessibility related theming
 */

// 'Skip to navigation' navigation
.keyboard-skip {
    position: fixed;
    display: none;
    pointer-events: none;
    z-index: 100;
    margin: calc(var(--ion-safe-area-top) + 8px) 0 0 calc(var(--ion-safe-area-left) + 8px);
    padding: 8px 16px;
    background-color: var(--ion-color-primary-shade);
    color: var(--ion-color-primary-contrast);
    font-size: var(--ion-font-size-3);
    font-weight: var(--ion-font-weight-bold);
    border-radius: 5px;
    -webkit-appearance: none;

    .plt-desktop & {
        display: inline-block;
        pointer-events: all;
        transform: translateY(-200%);
        transition: transform calc(.2s * var(--ion-animation-duration-multiplier));
        will-change: transform;

        &:focus {
            transform: translateY(0);
        }
    }
}
