.ion-page {

    // The tab bar is 'translucent', so account for is height in the bottom
    ion-tabs & {
        > ion-content {
            --padding-bottom: calc(var(--ion-safe-area-bottom) + var(--ion-tabs-height));

            &.ion-padding, &.ion-padding-vertical, &.ion-padding-bottom {
                --padding-bottom: calc(var(--ion-padding) + var(--ion-safe-area-bottom) + var(--ion-tabs-height));
            }
        }

        > ion-footer {
            margin-bottom: calc(16px + var(--ion-safe-area-bottom) + var(--ion-tabs-height));

            + ion-content {
                --padding-bottom: 0px;

                &.ion-padding, &.ion-padding-vertical, &.ion-padding-bottom {
                    --padding-bottom: var(--ion-padding);
                }
            }
        }
    }

}

// Hybrid app only:
// When the software keyboard is open, add padding to the bottom of the current page to match the keyboard height.
// This fixes issues where form inputs 'behind' the keyboard could not scroll into view
html.plt-android.keyboard-visible {
    .ion-page:not(ion-app):not(app-tabs):not(ion-popover .ion-delegate-host):not(.ion-page-hidden) {
        padding-bottom: calc(
            var(--keyboard-height)
            + var(--ion-safe-area-top)
            + var(--ion-safe-area-bottom)
            + var(--ion-android-safe-area-bottom, 0px)
            - var(--ion-tabs-height, 0px)
        );
    }

    ion-tabs .ion-page > ion-footer {
        margin-bottom: 0
    }
}

html.qr-scanner-visible {
    .ion-page:not(ion-app):not(app-tabs):not(.ion-delegate-host):not(.ion-page-hidden) {
        .content-card {
            --background: transparent;
        }

        ion-content {
            --background: transparent;
            --ion-color-base: transparent !important;
        }
    }
}
