ion-textarea {

    &,
    &.sc-ion-textarea-ios-h:not(.legacy-textarea),
    &.sc-ion-textarea-md-h:not(.legacy-textarea) {
        min-height: 40px;
        --padding-bottom: 0;
    }

    // 'Fix' the poor default outline styling to make it consistent on iOS and Android
    &[fill='outline'] {
        --border-radius: 5px;
        --border-width: 0 !important;
        --border-color: var(--ion-color-medium);
        --placeholder-color: var(--ion-color-medium);
        --placeholder-opacity: 1;

        .textarea-wrapper {
            --background: var(--ion-body-background);
            border: 1px solid var(--border-color) !important;
            box-shadow: var(--ion-box-shadow-inset);
            padding-inline-start: 0;
            padding-inline-end: 0;

            textarea {
                --min-height: 40px !important;
                padding: 8px 16px !important;
            }
        }

        .textarea-bottom {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }
    }

}
