ion-checkbox {
    --border-radius: 3px;
    --border-color: var(--ion-color-dark);
    --border-width: 2px;
    --size: 20px;
    --checkmark-width: 3px;

    ::ng-deep .label-text-wrapper {
        white-space: normal !important;
    }

    &:focus {
        --border-color: var(--ion-color-primary);
    }
}
