ion-back-button {
    --background: var(--ion-color-light);
    --background-hover: var(--ion-color-light-shade);
    --border-radius: 4px;
    --color: var(--ion-color-base) !important;
    --icon-font-size: var(--ion-font-size-3);
    --icon-margin-start: 0;
    --icon-margin-end: 4px;
    --padding-start: 4px;
    --padding-end: 6px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --margin-top: 8px;
    --margin-bottom: 8px;
    --margin-start: 5px;
    --margin-end: 5px;
    font-size: var(--ion-font-size-3);
    text-transform: none;
    padding-right: 10px;

    &.back-button-has-icon-only {
        --icon-margin-end: 0;
        --padding-start: 5px;
        --padding-end: 5px;
    }

    &:not(.ion-color) {
        --ion-color-base: var(--ion-color-primary);
    }

    &::part(native) {
        box-shadow: var(--ion-box-shadow);
    }

}
