$paddingMarginSizes: (
    -6: -64px,
    -5: -48px,
    -4: -24px,
    -3: -12px,
    -2: -8px,
    -1: -4px,
    0: 0,
    1: 4px,
    2: 8px,
    3: 12px,
    4: 24px,
    5: 48px,
    6: 64px,
    auto: auto,
);

$locations: (
    top,
    left,
    right,
    bottom,
);

$properties: (
    padding,
    margin,
);

@each $paddingMarginSize, $size in $paddingMarginSizes {
    @each $location in $locations {
        @each $property in $properties {
            .ion-#{$property}-#{$location}-#{$paddingMarginSize} {
                #{$property}-#{$location}: $size !important;
            }

            .ion-#{$property}-#{$location} {
                #{$property}-#{$location}: var(--ion-padding) !important;
            }
        }
    }

    @each $property in $properties {
        .ion-#{$property}-horizontal-#{$paddingMarginSize} {
            #{$property}-left: $size !important;
            #{$property}-right: $size !important;
        }

        .ion-#{$property}-vertical-#{$paddingMarginSize} {
            #{$property}-top: $size !important;
            #{$property}-bottom: $size !important;
        }

        .ion-#{$property}-#{$paddingMarginSize} {
            #{$property}-top: $size !important;
            #{$property}-right: $size !important;
            #{$property}-bottom: $size !important;
            #{$property}-left: $size !important;
        }
    }
}

.w-100 { width: 100%; }

.min-w-100 { min-width: 100%; }

.max-w-100 { max-width: 100%; }

.h-100 { height: 100%; }

.min-h-100 { min-height: 100%; }

.max-h-100 { max-height: 100%; }

.semi-hidden {
    position: fixed;
    pointer-events: none;
    visibility: hidden;
}

[hidden] {
    display: none !important; // This is somehow needed to fix issues with <ion-spinner> (in)visibility
}

.plt-desktop .hide-on-desktop {
    display: none !important;
}

.ion-no-margin-inline {
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.ion-no-inner-padding {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
}

.ion-flex {
    display: flex;
    flex-direction: column;
}

.ion-flex-row {
    flex-direction: row;
}

.ion-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ion-flex-grow {
    flex-shrink: 0;
    flex-grow: 1;
}

.ion-flex-shrink {
    flex-shrink: 1;
    flex-grow: 0;
}

.overflow-visible {
    overflow: visible;
}

.user-select-text {
    user-select: text;
}

.pointer-events-none {
    pointer-events: none;
}

.shadow {
    box-shadow: var(--app-box-shadow);
}

header.ion-color {
    background-color: var(--ion-color-base);

    > * {
        color: var(--ion-color-contrast);
    }
}

section.ion-color {
    background-color: var(--ion-color-tint);
}

.item-reset {
    --background: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --inner-padding-top: 0;
    --inner-padding-end: 0;
    --inner-padding-bottom: 0;
    --inner-padding-start: 0;
    --padding-start: 0;
    --padding-end: 0;
    --min-height: 0;
}

hr {
    display: block;
    height: 1px;
    margin: 36px 0;
    border: none;
    background: var(--ion-color-base, currentColor);
}

// Hide missing image icon
img {
    text-indent: -1000vw;
    overflow: hidden;
}

// Prevent any type of button from showing ugly default browser highlights/outlines
button, a, ion-button, .ion-activatable, [routerLink] {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
