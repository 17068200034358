ion-card {
    margin: 0;
    box-shadow: none;
    border: 1px solid var(--ion-color-medium);
    border-radius: 8px;

    // Use tint style for primary colored cards
    &.ion-color-primary {
        background: var(--ion-color-tint);
    }

    .card-content-md,
    .card-content-ios {
        @extend .reset-font;

        p {
            margin: 0;
        }
    }

    &.post-image-card {
        position: relative;
        font-size: 0;
        border-color: var(--ion-color-light);

        img {
            width: 100%;
        }

        ion-button {
            --padding-start: 6px;
            --padding-end: 6px;
            --box-shadow: var(--ion-box-shadow);
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    &.ion-activatable:focus {
        border: 1px solid var(--ion-color-primary);
    }

}
