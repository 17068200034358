ion-list {

    &.list-md {
        padding-top: 0;
        padding-bottom: 0;
    }

    // Horizontal lists should get centered, equal height items
    &.ion-flex-row {
        align-items: stretch;

        > ion-item {
            --min-height: 100%;
        }
    }

    // Add border to white lists within cards (white on white)
    ion-card:not(.ion-color) &:not(.ion-color):not([lines='none']) {
        border: 1px solid var(--ion-color-medium);

        &[lines='full'] {
            ion-item:last-of-type {
                --border-width: 0;
            }
        }
    }

}
