ion-avatar {
    &, img {
        border-radius: 100% !important;
    }

    &[size='small'] {
        width: 32px;
        height: 32px;
    }

    &:not([size]),
    &[size='medium'] {
        width: 48px;
        height: 48px;
    }

    &[size='large'] {
        width: 64px;
        height: 64px;
    }

    &[size='xlarge'] {
        width: 100px;
        height: 100px;
    }

    &.avatar-bordered {
        border: 3px solid var(--ion-body-background);
        background: var(--ion-body-background);
    }

    &.ion-activatable {
        cursor: pointer;
    }

}
