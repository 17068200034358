ion-tab-bar {
    --tab-indicator-width: 24px;
    --background: var(--ion-body-background);
    --border: 1px solid var(--ion-color-light);
    --color: var(--ion-color-secondary);
    height: calc(var(--ion-tabs-height) - 2px);
    padding-bottom: 0;
    border-radius: 16px;
    border: var(--border);
    margin: 16px 16px calc(var(--ion-safe-area-bottom, 0px) + 16px) 16px;
    box-shadow: var(--ion-box-shadow-large);

    &.tab-bar-translucent {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    ion-tab-button {
        position: relative;
        font-size: var(--ion-font-size-2);

        ion-label {
            font-weight: var(--ion-font-weight-bold);
            color: var(--ion-color-secondary);
        }

        ion-icon {
            font-size: var(--ion-font-size-5);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: 4px;
            height: 4px;
            width: var(--tab-indicator-width);
            margin-left: calc(var(--tab-indicator-width) / -2);
            border-radius: 2px;
            background: var(--ion-color-primary);
            transform: scaleX(0);
            opacity: 0;
            transition: transform calc(.2s * var(--ion-animation-duration-multiplier)) ease-out,
            opacity calc(.2s * var(--ion-animation-duration-multiplier)) ease-out;
            will-change: transform, opacity;
        }

        &.tab-selected::after {
            transform: scaleX(1);
            opacity: 1;
        }
    }

    // Center tabs on tablets
    @media(min-width: 768px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

}
