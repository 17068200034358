ion-modal {

    ion-back-button {
        display: block;
    }

    &.modal-sheet {
        &.md {
            --border-radius: 8px;
        }

        ion-toolbar {
            --border-width: 0 !important;
        }

        ion-title {
            text-align: left;
            padding-inline: 12px;
            font-size: var(--ion-font-size-5);
        }
    }

}
