ion-segment {
    --margin-top: 5px;
    --margin-end: 0;
    --margin-bottom: 5px;
    --margin-start: 0;
    border-radius: 24px;
    padding: 2px;

    &.ion-color {
        ion-segment-button {
            --indicator-color: var(--ion-color-base);
            --color: var(--ion-color-shade);
            --color-checked: var(--ion-color-contrast);
        }
    }

    &-button {
        --border-radius: 20px;
        --indicator-box-shadow: 0 2px 0 0 var(--app-shadow-color);
        min-height: 0;
        line-height: normal;

        ion-label {
            font-size: var(--ion-font-size-3);
            font-weight: var(--ion-font-weight-bold);
        }
    }
}
