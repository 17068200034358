ion-button {
    --border-radius: 4px;
    --min-height: 40px;
    --padding-start: 24px;
    --padding-end: 24px;
    height: auto;
    min-height: var(--min-height);
    font-size: var(--ion-font-size-3);
    font-weight: var(--ion-font-weight-bold);
    line-height: 1.5;
    text-transform: none;
    letter-spacing: normal;

    &::part(native) {
        min-height: var(--min-height);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:not([fill='clear']) {
        --box-shadow: var(--ion-box-shadow);
    }

    &[disabled] {
        --ion-color-base: var(--ion-text-muted-color) !important;
        opacity: 1;
    }

    &[size='small'] {
        --min-height: 32px;
        --padding-start: 12px;
        --padding-end: 12px;
        font-size: var(--ion-font-size-2);

        &.button-has-icon-only {
            &,
            .sc-ion-buttons-md-s &,
            .sc-ion-buttons-ios-s & {
                --padding-start: 4px;
                --padding-end: 4px;
                --padding-top: 4px;
                --padding-bottom: 4px;
            }

            .sc-ion-buttons-md-s &,
            .sc-ion-buttons-ios-s & {
                width: 40px;
                height: 40px;
            }
        }
    }

    &[size='large'] {
        --min-height: 56px;

        &:not([fill='clear']) {
            --box-shadow: var(--ion-box-shadow-large);
        }
    }

    // Circular buttons
    &[shape='circle'] {
        --border-radius: 50%;
        --padding-start: 0;
        --padding-end: 0;
        aspect-ratio: 1/1;

        &:not([size]) ion-icon {
            font-size: var(--ion-font-size-5);
        }
    }

    // Use primary text/icon color on light buttons
    &.ion-color-light {
        &:not([disabled]) {
            --ion-color-contrast: var(--ion-color-primary) !important;
        }

        &[disabled] {
            --ion-color-contrast: var(--ion-color-primary-contrast) !important;
        }
    }

    // Use white background on light buttons within a light-green card
    ion-card.ion-color-primary &, .chat-share-data & {
        &.ion-color-light:not(:hover) {
            --ion-color-base: var(--ion-color-primary-contrast) !important;
        }
    }

    &.modal-close-button {
        --min-height: 40px;
        --padding-start: 16px;
        --padding-end: 16px;
        margin: 12px;
    }

    &.unread {
        // Unread badge
        position: relative;
        font-weight: var(--ion-font-weight-bold);

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: -2px;
            right: -2px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: var(--ion-color-primary);
        }

        // Bold text
        ion-label {
            font-weight: var(--ion-font-weight-bold);
        }
    }
}

