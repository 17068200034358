ion-toast {
    --background: var(--ion-color-light);
    --color: var(--ion-text-color);
    --button-color: var(--ion-text-color);

    &::part(header) {
        font-weight: var(--ion-font-weight-bold);
    }

}
