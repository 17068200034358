ion-datetime-button {
    display: block;

    &::part(native) {
        padding-top: 0;
        padding-bottom: 4px;
        border-radius: 4px;
        padding-inline: 4px 6px;
        font-size: 21px;
        background: transparent;
    }

    ion-icon {
        vertical-align: middle;
        font-size: 25px;
    }

    &:active {
        color: var(--ion-color-medium);
    }

}
