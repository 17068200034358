ion-header {

    // Fix title fade on Android
    .md & {
        --opacity-scale: 1 !important;
    }

    .header-background {
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
    }

    &.header-collapse-condense-inactive {
        ion-toolbar {
            --border-width: 0 !important;
        }
    }

    &[collapse='condense'] {
        ion-toolbar {
            --min-height: 0;
            background: var(--ion-color-base);

            // Show white text on primary header
            &.ion-color-primary {
                > * {
                    color: var(--ion-color-b);
                }
            }
        }
    }

}

