ion-alert {

    .alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
        font-size: var(--ion-font-size-2);
    }

    .alert-button.sc-ion-alert-md {
        text-transform: none;
    }

    .alert-button.sc-ion-alert-md, .alert-button.sc-ion-alert-ios {
        font-size: var(--ion-font-size-3);
    }

    .alert-button-role-destructive.sc-ion-alert-md {
        color: var(--ion-color-danger);
    }

    .alert-input {
        &.sc-ion-alert-md,
        &.sc-ion-alert-ios {
            min-height: 40px;
            margin-bottom: 2px;
            padding: 8px 12px;
            border: 1px solid var(--ion-color-medium);
            border-radius: 5px;
            box-shadow: var(--ion-box-shadow-inset);

            &:focus {
                border-bottom: 1px solid var(--ion-color-medium);
                margin-bottom: 2px;
            }
        }
    }

}
