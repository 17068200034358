@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/nunito/Nunito-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

html {
    font-size: 100%;
}

.ion-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.ion-text-small {
    font-size: var(--ion-font-size-2);
    line-height: 1.43;
}

.ion-font-weight-normal {
    font-weight: var(--ion-font-weight-normal);
}

.ion-font-weight-semibold {
    font-weight: var(--ion-font-weight-semibold);
}

.ion-font-weight-bold {
    font-weight: var(--ion-font-weight-bold);
}

h1, h2, h3, h4, h5 {
    font-weight: var(--ion-font-weight-bold);
    color: var(--ion-color-secondary);
    user-select: none;
}

h1 {
    font-size: var(--ion-font-size-6);
    line-height: 1.5;
}

h2 {
    font-size: var(--ion-font-size-5);
    line-height: 1.33;
}

h3 {
    font-size: var(--ion-font-size-4);
    line-height: 1.6;
}

h4 {
    font-size: var(--ion-font-size-3);
    line-height: 1.5;
}

h5 {
    font-size: var(--ion-font-size-2);
    line-height: 1.43;
}

p {
    font-size: var(--ion-font-size-3);
    line-height: 2;
    color: var(--ion-text-color);
    user-select: none;
}

a:not([href]) {
    cursor: pointer;
    user-select: none;
}

p.ion-color,
span.ion-color,
a.ion-color {
    color: var(--ion-color-base);

    &:focus-visible {
        color: var(--ion-color-primary);
    }
}

a.no-underline {
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
    }
}

.ion-text-muted {
    color: var(--ion-text-muted-color) !important;
}

.keep-white-space {
    white-space: pre-wrap;
}

.ion-font-size-1 { font-size: var(--ion-font-size-1); }

.ion-font-size-2 { font-size: var(--ion-font-size-2); }

.ion-font-size-3 { font-size: var(--ion-font-size-3); }

.ion-font-size-4 { font-size: var(--ion-font-size-4); }

.ion-font-size-5 { font-size: var(--ion-font-size-5); }

.ion-font-size-6 { font-size: var(--ion-font-size-6); }

.reset-font {
    font-size: var(--ion-font-size-3);
    color: var(--ion-text-color);

    h1 { @extend h1; }

    h2 { @extend h2; }

    h3 { @extend h3; }

    h4 { @extend h4; }

    h5 { @extend h5; }

    p { @extend p; }
}
