// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #009B77;
    --ion-color-primary-rgb: 0, 155, 119;
    --ion-color-primary-contrast: #FFFFFF;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #017977;
    --ion-color-primary-tint: #EAF7F4;
    --ion-color-primary-hover: #14A382;

    --ion-color-secondary: #0A1738;
    --ion-color-secondary-rgb: 10, 23, 56;
    --ion-color-secondary-contrast: #FFFFFF;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #091431;
    --ion-color-secondary-tint: #232E4C;

    --ion-color-tertiary: #F1873A;
    --ion-color-tertiary-rgb: 241, 135, 58;
    --ion-color-tertiary-contrast: #0A1738;
    --ion-color-tertiary-contrast-rgb: 10, 23, 56;
    --ion-color-tertiary-shade: #D47733;
    --ion-color-tertiary-tint: #F2934E;

    --ion-color-success: #017977;
    --ion-color-success-rgb: 1, 121, 119;
    --ion-color-success-contrast: #FFFFFF;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #016A69;
    --ion-color-success-tint: #1A8685;

    --ion-color-warning: #FFC409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #E0AC08;
    --ion-color-warning-tint: #FFCA22;

    --ion-color-danger: #EF7F72;
    --ion-color-danger-rgb: 239, 127, 114;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #D27064;
    --ion-color-danger-tint: #F18C80;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #FFFFFF;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1E2023;
    --ion-color-dark-tint: #383A3E;

    --ion-color-medium: #C1C3C8;
    --ion-color-medium-rgb: 193, 195, 200;
    --ion-color-medium-contrast: #0A1738;
    --ion-color-medium-contrast-rgb: 10, 23, 56;
    --ion-color-medium-shade: #AAACB0;
    --ion-color-medium-tint: #C7C9CE;

    --ion-color-light: #EEEEEE;
    --ion-color-light-rgb: 238, 238, 238;
    --ion-color-light-contrast: #0A1738;
    --ion-color-light-contrast-rgb: 10, 23, 56;
    --ion-color-light-shade: #D1D1D1;
    --ion-color-light-tint: #F0F0F0;

    --ion-font-family: 'Nunito', sans-serif;
    --ion-text-color: var(--ion-color-secondary);


    --ion-padding: 24px;

    // Custom
    --ion-body-background: #FFFFFF;
    --ion-text-muted-color: #7F8187;

    --ion-tabs-height: 80px;

    --ion-box-shadow: 0 2px 4px 0 rgba(var(--ion-color-secondary-rgb), .08);
    --ion-box-shadow-large: 0 2px 8px 0 rgba(var(--ion-color-secondary-rgb), .08);
    --ion-box-shadow-inset: inset 0 2px 4px 0 rgba(var(--ion-color-secondary-rgb), .08);

    --ion-font-weight-normal: 400;
    --ion-font-weight-semibold: 600;
    --ion-font-weight-bold: 700;

    --ion-font-size-1: 0.75rem;
    --ion-font-size-2: 0.875rem;
    --ion-font-size-3: 1rem;
    --ion-font-size-4: 1.25rem;
    --ion-font-size-5: 1.5rem;
    --ion-font-size-6: 2rem;

    --ion-animation-duration-multiplier: 1;

    --custom-scrollbar-width: 8px;
    --custom-scrollbar-height: 8px;
    --custom-scrollbar-margin: 8px;
    --custom-scrollbar-border-radius: 4px;
    --custom-scrollbar-track-background: var(--ion-color-light);
    --custom-scrollbar-thumb-background: var(--ion-color-primary);
    --custom-scrollbar-thumb-hover-background: var(--ion-color-primary-hover);

    @media(prefers-reduced-motion: reduce) {
        --ion-animation-duration-multiplier: 0;
    }

    @media(prefers-contrast: less) {
        --ion-color-primary: #61A89A;
        --ion-color-primary-rgb: 97, 168, 154;
    }

    @media(prefers-contrast: more) {
        --ion-color-primary: #005D48;
        --ion-color-primary-rgb: 93, 72, 100;
        --ion-text-color: #000000;
    }

    @media(prefers-contrast: custom) {
        --ion-color-primary: #222222;
        --ion-color-primary-rgb: 34, 34, 24;
        --ion-color-primary-shade: #000000;
        --ion-color-primary-tint: #CCCCCC;
        --ion-text-color: #000000;
        --ion-text-muted-color: #888888;
    }

    @media(forced-colors: active) {
        --ion-color-primary: #222222;
        --ion-color-primary-rgb: 34, 34, 24;
        --ion-color-primary-shade: #000000;
        --ion-color-primary-tint: #CCCCCC;

        * {
            forced-color-adjust: none;
        }
    }

}
