ion-input {

    // 'Fix' the poor default outline styling to make it consistent on iOS and Android
    &[fill='outline'] {
        --background: var(--ion-body-background);
        --border-color: var(--ion-color-medium);
        --placeholder-color: var(--ion-color-medium);
        --placeholder-opacity: 1;
        --padding-top: 4px;
        --padding-bottom: 4px;
        --padding-start: 16px;
        --padding-end: 16px;
        --border-radius: 5px !important;
        min-height: 40px !important;
        border: none;
        border-radius: var(--border-radius);

        // Move label outside the input container
        &[labelPlacement='stacked'],
        &[label-placement='stacked'] {
            margin-top: max(1.25rem, 26px);

            // Remove the default label styling
            .label-text-wrapper {
                transform: none !important;
                max-width: none !important;
                position: absolute;
                top: calc(-1 * max(1.25rem, 26px));
                left: 0;
                font-size: var(--ion-font-size-2);
                font-weight: var(--ion-font-weight-bold);
            }

            // Move 'remaining characters counter' to the top
            .counter {
                position: absolute;
                top: -22px;
                right: 0;
            }
        }

        // Hide unnecessary outline container on Android
        .input-outline-container {
            display: none !important;
        }

        // Add the border to a more appropriate element
        .input-wrapper {
            border: 1px solid var(--border-color) !important;
            box-shadow: var(--ion-box-shadow-inset);
        }

        // Remove extra margin
        .native-input {
            margin: 0 !important;
        }

        // Error and help texts don't need a spacing on the left side
        .input-bottom {
            --padding-start: 0;
            border: none;
            justify-content: flex-start;

            .error-text {
                font-size: var(--ion-font-size-2);
            }
        }

    }

    // Add-in button (such as password peek)
    ion-button[slot='end'],
    ion-datetime-button[slot='end'] {
        --padding-start: 8px;
        --padding-end: 8px;
        --box-shadow: none;
        margin-inline-end: -12px !important;
        margin-block: -6px;
        pointer-events: auto;

        &::before {
            content: '';
            display: block;
            position: absolute;
            margin-left: -3px;
            width: 1px;
            top: 1px;
            bottom: 1px;
            background: var(--ion-color-medium);
        }

        ion-icon[slot='icon-only'] {
            font-size: 25px;
        }
    }

    html[dir='rtl'] & {
        > ion-button[slot='end'],
        > ion-datetime-button[slot='end'] {
            right: auto;
            left: 2px;
        }
    }

    > ion-datetime-button[slot='end'] {
        top: 4px;
    }

}
