.str-chat {

    // Colors
    --str-chat__primary-color: var(--ion-color-primary);
    --str-chat__primary-overlay-color: rgba(var(--ion-color-primary-rgb), .6);
    --str-chat__active-primary-color: var(--ion-color-primary-shade);
    --str-chat__surface-color: var(--ion-color-medium);
    --str-chat__secondary-surface-color: var(--ion-body-background);
    --str-chat__primary-surface-color: var(--ion-color-primary-tint);
    --str-chat__primary-surface-color-low-emphasis: var(--ion-color-primary);
    --str-chat__own-message-reaction-color: var(--ion-color-primary-contrast);
    --str-chat__channel-preview-color: var(--ion-color-secondary);
    --str-chat__channel-preview-border-block-end: 1px solid var(--ion-color-light);

    // Typography
    --str-chat__font-family: var(--ion-font-family);

    // Chat messages
    --str-chat__deleted-message-background-color: var(--ion-color-light);
    --str-chat__message-bubble-border-radius: 8px;
    --str-chat__message-bubble-border-block-start: 1px solid var(--ion-color-medium);
    --str-chat__message-bubble-border-block-end: 1px solid var(--ion-color-medium);
    --str-chat__message-bubble-border-inline-start: 1px solid var(--ion-color-medium);
    --str-chat__message-bubble-border-inline-end: 1px solid var(--ion-color-medium);

    // Input toolbar
    --str-chat__message-input-background-color: var(--ion-color-primary-tint);
    --str-chat__message-send-background-color: var(--ion-color-primary);
    --str-chat__message-send-color: var(--ion-color-primary-contrast);
    --str-chat__message-send-disabled-background-color: var(--ion-color-primary);
    --str-chat__message-send-disabled-color: var(--ion-color-medium);
    --str-chat__message-send-border-radius: 5px;

    // Message input
    --border-color: var(--ion-color-medium);
    --str-chat__message-textarea-background-color: var(--ion-body-background);
    --str-chat__message-textarea-border-radius: 5px;
    --str-chat__message-textarea-border-block-start: 1px solid var(--border-color);
    --str-chat__message-textarea-border-block-end: 1px solid var(--border-color);
    --str-chat__message-textarea-border-inline-start: 1px solid var(--border-color);
    --str-chat__message-textarea-border-inline-end: 1px solid var(--border-color);
    --str-chat__message-textarea-box-shadow: var(--ion-box-shadow-inset);

    // Chat message
    &__message {
        &-bubble {
            --str-chat__message-max-width: 400px;
            overflow: hidden;

            img {
                display: block; // Fix whitespace below images
            }

            app-post-card {
                display: block;
                margin: 3px;
                border-radius: 8px 8px 0 0;
                padding: 16px;
                background: var(--ion-body-background);

                @media(max-width: 375px) {
                    zoom: .85;
                }

                > ion-item {
                    padding-top: 0 !important;
                }

                .post-content div.ion-margin-bottom {
                    margin-bottom: 0 !important;
                }

                ::ng-deep {
                    .post-image-card {
                        max-width: 200px;
                    }
                }
            }
        }

        &-text-inner {
            span {
                white-space: pre-wrap;
            }
        }

        &-input {
            padding: 16px 16px calc(16px + var(--ion-safe-area-bottom)) 16px;
        }

        &-textarea {
            padding: 2px !important;
        }

        // Hide certain elements
        &-simple-status, &-simple-timestamp, &-actions-container, &-reactions-host {
            display: none !important;
        }
    }

    // Input toolbar {
    &__file-input-container {
        order: 1;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin: 0 8px;
        background: var(--ion-color-primary-tint);
        transition: background-color .3s ease;

        &:active {
            background: rgba(var(--ion-color-primary-rgb), .25);
        }

        stream-icon {
            background: url('/assets/images/camera.svg') no-repeat center / contain;

            > svg {
                visibility: hidden;
            }
        }
    }

    &__send-button {
        order: 2;
        transition: background-color .3s ease;

        &:active {
            --str-chat__message-send-background-color: var(--ion-color-primary-shade);
        }

        stream-icon-placeholder {
            pointer-events: none;
        }

        stream-icon {
            pointer-events: none;
            position: relative;
            left: -2px;
        }
    }

    // Textarea
    &__textarea__textarea {
        &::placeholder {
            color: var(--ion-color-medium);
        }
    }

    // Attachment previews
    &__attachment-preview {
        stream-icon-placeholder {
            pointer-events: none;
        }

        &-image {
            flex-shrink: 0;
        }

        &-file {
            flex-shrink: 0;

            stream-icon-placeholder[icon='unspecified-filetype'] {
                display: none;
            }
        }
    }

    &__unread-messages-separator-wrapper, &__unread-messages-separator {
        display: none;
    }
}

.chat-attachment-image {
    cursor: pointer;
}

.chat-attachment-file {
    --border-radius: 8px;
}

// Limit line height on 'last message' text in channel list items
.chat-channel-item ion-label p {
    line-height: normal !important;
}

// Show single emoji messages larger
stream-message[data-is-single-emoji='true'] .str-chat__message-text-inner p > span {
    font-size: var(--ion-font-size-6) !important;
    line-height: calc(var(--ion-font-size-6) * 1.5) !important;
}

stream-attachment-preview-list {
    width: 100%;
}
