.tablet-max-400 {
    @media(min-width: 768px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}

.tablet-max-600 {
    @media(min-width: 768px) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

.tablet-card {
    @media(min-width: 768px) {
        display: block;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        padding: 16px 24px;
        border-radius: 8px;
        box-shadow: var(--ion-box-shadow-large);
        overflow: hidden;

        > ion-card.ion-margin-top:only-child {
            margin-top: 0 !important;
        }
    }
}

ion-button[expand='block'] {
    @media(min-width: 768px) {
        max-width: 400px;

        ion-footer & {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
